import {AxiosResponse} from 'axios';
import ApiService from '@/services/_helper/api.service';

export default class ConsentService {
  static getConsentList(params: {pageNumber: number; itemsCount: number}): Promise<AxiosResponse> {
    return ApiService.get('/consent', {params});
  }
  static changeConsent(payload: {id: number; params: {version: string; frontendContentId: number}}) {
    return ApiService.put(`/consent/${payload.id}`, payload.params);
  }
}
